import { BaseController } from './BaseController'

import { components } from 'Types/api'

export interface Access {
  enabled: boolean
  permission: string
}
export type PermissionsType = components['schemas']['SessionListItemPermissions']
export type Invites = components['schemas']['Invite']
export type Stats = components['schemas']['Stats']
export type AccountRegister = components['schemas']['AccountRegister']
export type Session = components['schemas']['Session']
export type Sessions = components['schemas']['SessionsResponse']
export type SessionList = components['schemas']['SessionListItem']
export type Visitor = components['schemas']['VisitorInvite']
export type Visitors = components['schemas']['VisitorsResponse']
export type VisitorsList = components['schemas']['VisitorsListItem']
// export type PageFilters = components["schemas"]["PageFilters"]
export type DuoReset = components['schemas']['DuoReset']
export type PasswordReset = components['schemas']['PasswordReset']
export type ProfilePictures = components['schemas']['ProfilePicturePerson']
export type ProfilePictureReject = components['schemas']['ProfilePicturePut']
export type PictureRejectReasons = components['schemas']['PictureRejectReasons']
export type PictureSignedUrl = components['schemas']['SignedUrl']
export type InviteRevokeBody = components['schemas']['InviteRevoke']
export type AccountRegisterExtendBody = components['schemas']['AccountRegisterExtend']
// export type Sponsor = components["schemas"]["Sponsor"]
// export type Sponsors = components["schemas"]["Sponsors"]
// export type SponsorsBody = components["schemas"]["SponsorUpdate"]
// export type Department = components["schemas"]["Department"]
// export type Departments = components["schemas"]["Departments"]
export type Resolution = 'low' | 'high'

export class AccountController extends BaseController {
  readonly url_admin_permissions: string
  readonly url_admin_invites: string
  readonly url_admin_stats: string
  readonly url_admin_register: string
  readonly url_admin_register_extend: string
  readonly url_admin_sessions: string
  readonly url_admin_visitors: string
  readonly url_admin_page_filters: string
  readonly url_admin_duo_reset: string
  readonly url_admin_password_reset: string
  readonly url_admin_picture: string
  readonly url_admin_picture_reject_reasons: string
  readonly url_admin_picture_signed_url: string
  readonly url_admin_revoke: string
  readonly url_admin_suspend: string
  readonly url_admin_sponsors: string
  readonly url_admin_departments: string

  constructor () {
    super()

    this.url_admin_permissions = `${this.apiHost}/${this.apiPathAccount}/admin/permissions`
    this.url_admin_invites = `${this.apiHost}/${this.apiPathAccount}/admin/invites`
    this.url_admin_stats = `${this.apiHost}/${this.apiPathAccount}/admin/stats`
    this.url_admin_register = `${this.apiHost}/${this.apiPathAccount}/admin/register`
    this.url_admin_register_extend = `${this.apiHost}/${this.apiPathAccount}/admin/register/extend`
    this.url_admin_sessions = `${this.apiHost}/${this.apiPathAccount}/admin/sessions`
    this.url_admin_page_filters = `${this.apiHost}/${this.apiPathAccount}/admin/sessions/page-filters`
    this.url_admin_visitors = `${this.apiHost}/${this.apiPathAccount}/admin/visitors`
    this.url_admin_duo_reset = `${this.apiHost}/${this.apiPathAccount}/admin/reset/duo`
    this.url_admin_password_reset = `${this.apiHost}/${this.apiPathAccount}/admin/reset/password`
    this.url_admin_picture = `${this.apiHost}/${this.apiPathAccount}/admin/picture`
    this.url_admin_picture_reject_reasons = `${this.apiHost}/${this.apiPathAccount}/admin/picture/reject-reasons`
    this.url_admin_picture_signed_url = `${this.apiHost}/${this.apiPathAccount}/admin/picture/url`
    this.url_admin_revoke = `${this.apiHost}/${this.apiPathAccount}/admin/revoke-initiation`
    this.url_admin_suspend = `${this.apiHost}/${this.apiPathAccount}/admin/suspend`
    this.url_admin_sponsors = `${this.apiHost}/${this.apiPathAccount}/admin/sponsor`
    this.url_admin_departments = `${this.apiHost}/${this.apiPathAccount}/admin/departments`
  }

  checkStatusCode (status: number): boolean {
    return status >= 200 && status < 300
  }

  async getPermissions (mitId?: string): Promise<Access[]> {
    let response
    if (mitId !== undefined) response = await this.useFetch('GET', 'getPermissions', this.url_admin_permissions + `?mitId=${mitId}`)
    else response = await this.useFetch('GET', 'getPermissions', this.url_admin_permissions)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      // const hack = data.find((item: any) => item.permission === 'viewAffiliates')
      // if (hack) hack.enabled = false
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getAdminInvites (options?: string): Promise<Invites[]> {
    let response
    if (options !== undefined) {
      response = await this.useFetch('GET', 'adminInvites', `${this.url_admin_invites}?${options}`)
    } else {
      response = await this.useFetch('GET', 'adminInvites', this.url_admin_invites)
    }

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getAdminStats (): Promise<Stats> {
    const response = await this.useFetch('GET', 'adminStats', this.url_admin_stats)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async postAdminRegister (body: AccountRegister): Promise<AccountRegister> {
    const response = await this.useFetch('POST', 'register', this.url_admin_register, body)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async putAccountExtend (body: AccountRegisterExtendBody): Promise<any> {
    const response = await this.useFetch('PUT', 'accountExtend', this.url_admin_register_extend, body)

    if (this.checkStatusCode(response.status)) {
      return true
      // const data = await response.json()
      // return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getSession (mitId: string): Promise<Session> {
    const response = await this.useFetch('GET', 'adminSessions', this.url_admin_sessions + `/${mitId}`)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getSessions (page?: string, options?: string, csv: boolean = false): Promise<Sessions> {
    const query = [
      csv ? '/csv' : null,
      // `?page=${page}`,
      options !== undefined ? `${options}` : null
    ].filter(itm => itm !== null).join('')

    const response = await this.useFetch('GET', 'adminInvites', `${this.url_admin_sessions}${query}`)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getVisitors (): Promise<Visitors> {
    const response = await this.useFetch('GET', 'adminVisitor', this.url_admin_visitors)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getVisitor (mitId: string): Promise<Visitor> {
    const response = await this.useFetch('GET', 'adminVisitors', this.url_admin_visitors + `/${mitId}`)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  // async getPageFilters (): Promise<PageFilters> {
  //   const response = await this.basicFetch('GET', 'pageFilters', this.url_admin_page_filters)

  //   if (this.checkStatusCode(response.status)) {
  //     const data = await response.json()
  //     return data
  //   } else {
  //     const text = await response.text()
  //     return await Promise.reject(text)
  //   }
  // }

  async duoReset (mitId: string): Promise<DuoReset> {
    const response = await this.useFetch('PUT', 'adminDuoReset', this.url_admin_duo_reset, { mitId })

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async passwordReset (mitId: string): Promise<DuoReset> {
    const response = await this.useFetch('PUT', 'adminPasswordReset', this.url_admin_password_reset, { mitId })

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getProfilePic (mitId: string): Promise<any> {
    const response = await this.useFetch('GET', 'getProfilePicture', this.url_admin_picture + `?mitId=${mitId}`)

    if (this.checkStatusCode(response.status)) {
      return response.json()
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getProfilePicture (mitId: string, reslution?: Resolution): Promise<ProfilePictures> {
    let response
    if (reslution !== undefined) {
      response = await this.useFetch('GET', 'getProfilePicture' + mitId, `${this.url_admin_picture}?mitId=${mitId}&resolution=${reslution}`)
    } else {
      response = await this.useFetch('GET', 'getProfilePicture' + mitId, `${this.url_admin_picture}?mitId=${mitId}`)
    }

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getPictureSignedUrl (mitId: string): Promise<PictureSignedUrl> {
    const response = await this.useFetch('GET', 'PictureSignedUrl', this.url_admin_picture_signed_url + `/${mitId}`)
    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async putProfilePictureReject (body: ProfilePictureReject): Promise<ProfilePictureReject> {
    const response = await this.useFetch('PUT', 'putProfilePictureReject', this.url_admin_picture, body)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async uploadProfilePicture (url: string | any, file: any, fileName?: any): Promise<any> {
    const response = await this.useFetch('PUT', 'put/upload', url, file, undefined, true)

    if (response.status >= 200 && response.status < 300) {
      return true
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getPictureReject (): Promise<PictureRejectReasons> {
    const response = await this.useFetch('GET', 'getPictureReject', this.url_admin_picture_reject_reasons)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async inviteRevoke (body: InviteRevokeBody): Promise<any> {
    const response = await this.useFetch('PUT', 'inviteRevoke', this.url_admin_revoke, body)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async inviteSuspend (body: InviteRevokeBody): Promise<any> {
    const response = await this.useFetch('PUT', 'inviteRevoke', this.url_admin_suspend, body)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  // async getSponsors (): Promise<Sponsors> {
  //   const response = await this.useFetch('GET', 'getSponsors', this.url_admin_sponsors)

  //   if (this.checkStatusCode(response.status)) {
  //     const data = await response.json()
  //     return data
  //   } else {
  //     const text = await response.text()
  //     return await Promise.reject(text)
  //   }
  // }

  // async sponsorsUpdate (body: SponsorsBody): Promise<any> {
  //   const response = await this.useFetch('PUT', 'sponsorsUpdate', this.url_admin_sponsors, body)

  //   if (this.checkStatusCode(response.status)) {
  //     const data = await response.json()
  //     return data
  //   } else {
  //     const text = await response.text()
  //     return await Promise.reject(text)
  //   }
  // }

  // async getDepartments (): Promise<Departments> {
  //   const response = await this.useFetch('GET', 'getDepartments', this.url_admin_departments)

  //   if (this.checkStatusCode(response.status)) {
  //     const data = await response.json()
  //     return data
  //   } else {
  //     const text = await response.text()
  //     return await Promise.reject(text)
  //   }
  // }
}
