// import { ListItemProps } from '@mit/hui'
import { Session, Stats } from 'Api/controller/AccountController'
import { Action, action } from 'easy-peasy'

export enum ActiveDrawer {
  None,
  ShowWhatsNew,
  UserDetails,
  ReviewDetail
}

export enum ActivePage {
  None,
  Invites,
  Affiliates,
  Staff,
  Students,
  Visitors
}

export const userDetailsDefault = {
  department: '',
  duoDone: false,
  duoDoneDate: '',
  expired: false,
  expiryDate: '',
  firstName: '',
  history: [],
  inviteDate: '',
  inviteEmail: '',
  invited: false,
  kerbDone: false,
  kerbDoneDate: '',
  kerbName: '',
  lastName: '',
  mitId: '',
  mitIdCardDone: false,
  mitIdCardDoneDate: '',
  mobileIdDone: false,
  mobileIdDoneDate: '',
  onboardingCompletedPercentage: 0,
  onboardingEnabled: false,
  onboardingStatus: '',
  onboardingTasksCompleted: 0,
  onboardingTasksTotal: 0,
  passwordDone: false,
  passwordDoneDate: '',
  permissions: {
    canChangeDOB: false,
    canChangeName: false,
    canChangeNonMitEmail: false,
    canCreateNewAccount: false,
    canResendInvite: false,
    canResetDUO: false,
    canResetKerberos: false,
    canResetPassword: false,
    canReviewProfilePic: false,
    canRevoke: false,
    canSuspend: false,
    canUpdateDLC: false,
    canUpdateExpiryDate: false,
    canUpdateSponsor: false,
    canViewHistory: false,
    canViewMitIdCardStatus: false,
    canViewMobileIDStatus: false,
    canViewOnboardingProgress: false
  },
  pictureDone: false,
  pictureDoneDate: '',
  type: ''
}
interface State {
  activeDrawer: ActiveDrawer
  activePage: ActivePage
  userDetails: Session
  profilePicture: string
  reload: boolean
  fetchImages: boolean
  activeSessionId: string
  stats: Stats
}
interface Actions {
  setActiveDrawer: Action<this, ActiveDrawer>
  setActivePage: Action<this, ActivePage>
  setUserDetails: Action<this, Session>
  setProfilePicture: Action<this, string>
  setReload: Action<this, boolean>
  setFetchImages: Action<this, boolean>
  setActiveSessionId: Action<this, string>
  setStats: Action<this, Stats>
}
// eslint-disable-next-line
interface Thunks {}

export interface IAccountModel extends State, Actions, Thunks {}

export const AccountModel: IAccountModel = {
  activeDrawer: ActiveDrawer.None,
  activePage: ActivePage.Affiliates,
  userDetails: userDetailsDefault,
  profilePicture: '',
  reload: false,
  fetchImages: false,
  activeSessionId: '',
  stats: {
    users: 0,
    ready: 0,
    mobileIdDone: 0,
    mitIdCardDone: 0,
    dailyStats: []
  },

  setActiveDrawer: action((state, payload) => {
    state.activeDrawer = payload
  }),

  setActivePage: action((state, payload) => {
    state.activePage = payload
  }),

  setUserDetails: action((state, payload) => {
    state.userDetails = payload
  }),

  setProfilePicture: action((state, payload) => {
    state.profilePicture = payload
  }),

  setReload: action((state, payload) => {
    state.reload = payload
  }),

  setFetchImages: action((state, payload) => {
    state.fetchImages = payload
  }),

  setActiveSessionId: action((state, payload) => {
    state.activeSessionId = payload
  }),

  setStats: action((state, payload) => {
    state.stats = payload
  })
}
