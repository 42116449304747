import { MITLoggedOut } from '@mit/hui'
import { useHistory } from 'react-router-dom'

import React from 'react'

type History = ReturnType<typeof useHistory>

interface LogoutProps {
  history: History
}

export const Logout: React.FC<LogoutProps> = ({ history }) => {
  const hasReauthenticated = localStorage.getItem('RefreshAuthentication') !== null
  if (hasReauthenticated) {
    localStorage.removeItem('RefreshAuthentication')
    history.push('/')
  }

  return <MITLoggedOut />
}
