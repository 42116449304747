import { getStage } from '@mit/aws-react'
import appConfig from '../app-config'

type EnvReturn = 'dev' | 'staging' | 'production'
type EnvReturnUrl = 'dev' | 'develop' | 'staging' | 'release' | 'production' | ''

export const getEnv = (): EnvReturn => {
  switch (getStage(appConfig)) {
    case 'develop':
      return 'dev'
    case 'release':
      return 'staging'
    case 'master':
      return 'production'
  }
  return 'production'
}

export const getEnvUrl = (): EnvReturnUrl => {
  switch (getStage(appConfig)) {
    case 'develop':
      return 'develop'
    case 'release':
      return 'release'
    case 'master':
      return ''
  }
}
