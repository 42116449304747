import { BaseController } from './BaseController'

import { Profile } from 'Types'

export class ProfileController extends BaseController {
  public async getProfile (): Promise<Profile> {
    const response = await this.useFetch('GET', 'profile', `${this.apiHost}/${this.apiPathDigitalId}/profile`)

    if (response.status === 200) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getProfilePicture (): Promise<Blob> {
    const response = await this.useFetch('GET', 'picture', `${this.apiHost}/${this.apiPathDigitalId}/picture`)

    if (response.status === 200) {
      return response.blob()
    }
    const text = await response.text()
    return await Promise.reject(text)
  }
}
