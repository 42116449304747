import { persist } from 'easy-peasy'

// APP SPECIFIC STORES - EDIT THESE OR ADD MORE HERE:
import { IAppConfigModel, AppConfigModel } from './AppConfig'
import { IGlobalModel, GlobalModel } from './Global'
import { ILocalStorageModel, LocalStorageModel } from './LocalPresist'
import { ISessionStorageModel, SessionStorageModel } from './SessionPresist'
import { IProfileModel, ProfileModel } from './Profile'
import { IAccountModel, AccountModel } from './Account'
import { ILookupModel, LookupModel } from './Lookup'

export interface Model {
  AppConfigModel: IAppConfigModel
  GlobalModel: IGlobalModel
  LocalStorageModel: ILocalStorageModel
  ProfileModel: IProfileModel
  SessionStorageModel: ISessionStorageModel
  AccountModel: IAccountModel
  LookupModel: ILookupModel
}

export const model: Model = {
  // FOR APP CONFIG MANEGEMENT - AppConfigModel
  AppConfigModel,
  // FOR GLOBAL STATE MANAGEMENT - GlobalModel
  GlobalModel,
  // FOR PROFILE STATE MANAGEMENT - ProfileModel
  ProfileModel,
  // FOR LOCALSTORAGE MANAGEMENT - LocalStorageModel
  LocalStorageModel: persist(LocalStorageModel, {
    storage: 'localStorage'
  }),
  // FOR SESSIONSTORAGE MANAGEMENT - SESSIONSTORAGE
  SessionStorageModel: persist(SessionStorageModel, {
    storage: 'sessionStorage'
  }),
  AccountModel,
  LookupModel
}
