import { AtlasTheme } from '@mit/hui'

interface State {
  appName: string
  appDescription: string
  appContact: string
  appTheme: AtlasTheme
  googleAnalyticsId: string
}
// eslint-disable-next-line
interface Actions {}
// eslint-disable-next-line
interface Thunks {}

export interface IAppConfigModel extends State, Actions, Thunks {}

export const AppConfigModel: IAppConfigModel = {
  appName: 'Account Admin',
  appDescription: 'An MIT Atlas app.',
  appContact: 'help@mit.edu',
  appTheme: 'medical',
  googleAnalyticsId: 'g-xxxxxxxx'
}
