import { ListItemProps } from '@mit/hui'
// import { Session, Stats } from 'Api/controller/AccountController'
import { Action, action } from 'easy-peasy'

export interface SponsorListItem extends ListItemProps {
  mitId?: string
  kerb?: string
  dlc?: string
}
export interface DepartmentListItems {
  affiliates: ListItemProps[]
  staff: ListItemProps[]
  student: ListItemProps[]
}
export interface StatusListItem extends ListItemProps {
  decoratedText?: string
  filterKey?: string
  selected?: boolean
}
interface State {
  sponsorsList: SponsorListItem[] | []
  departmentsList: DepartmentListItems
  searchKey: string | undefined
  searchParams: string[]
  statusList: StatusListItem[] | []
  reload: boolean
}
interface Actions {
  setSponsorsList: Action<this, SponsorListItem[] | []>
  setDepartmentsList: Action<this, DepartmentListItems>
  setSearchKey: Action<this, string | undefined>
  setSearchParams: Action<this, string[]>
  setStatusList: Action<this, StatusListItem[] | []>
  setReload: Action<this, boolean>
}
// eslint-disable-next-line
interface Thunks {}

export interface ILookupModel extends State, Actions, Thunks {}

export const LookupModel: ILookupModel = {
  sponsorsList: [],
  departmentsList: {
    affiliates: [],
    staff: [],
    student: []
  },
  searchKey: undefined,
  searchParams: [],
  statusList: [],
  reload: false,

  setSponsorsList: action((state, payload) => {
    state.sponsorsList = payload
  }),

  setDepartmentsList: action((state, payload) => {
    state.departmentsList = payload
  }),

  setSearchKey: action((state, payload) => {
    state.searchKey = payload
  }),

  setSearchParams: action((state, payload) => {
    state.searchParams = payload
  }),

  setStatusList: action((state, payload) => {
    state.statusList = payload
  }),

  setReload: action((state, payload) => {
    state.reload = payload
  })
}
