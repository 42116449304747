import { BaseController } from './BaseController'

import { components } from 'Types/api'

export type PageFilters = components['schemas']['PageFilters']
export type StatusFilter = components['schemas']['StatusFilter']
export type Sponsor = components['schemas']['Sponsor']
export type Sponsors = components['schemas']['Sponsors']
export type SponsorsBody = components['schemas']['SponsorUpdate']
export type DepartmentGroup = components['schemas']['DepartmentGroups']
export type Department = components['schemas']['Department']
export type Departments = components['schemas']['Departments']

export class LookupController extends BaseController {
  readonly url_admin_page_filters: string
  readonly url_admin_sponsors: string
  readonly url_admin_departments: string
  readonly url_admin_filters_status: string

  constructor () {
    super()

    this.url_admin_page_filters = `${this.apiHost}/${this.apiPathAccount}/admin/sessions/page-filters`
    this.url_admin_sponsors = `${this.apiHost}/${this.apiPathAccount}/admin/sponsor`
    this.url_admin_departments = `${this.apiHost}/${this.apiPathAccount}/admin/departments`
    this.url_admin_filters_status = `${this.apiHost}/${this.apiPathAccount}/admin/filters/status`
  }

  checkStatusCode (status: number): boolean {
    return status >= 200 && status < 300
  }

  async getPageFilters (): Promise<PageFilters> {
    const response = await this.useFetch('GET', 'pageFilters', this.url_admin_page_filters)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getSponsors (): Promise<Sponsors> {
    const response = await this.useFetch('GET', 'getSponsors', this.url_admin_sponsors)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async sponsorsUpdate (body: SponsorsBody): Promise<any> {
    const response = await this.useFetch('PUT', 'sponsorsUpdate', this.url_admin_sponsors, body)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getDepartments (): Promise<DepartmentGroup> {
    const response = await this.useFetch('GET', 'getDepartments', this.url_admin_departments)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getStatus (): Promise<StatusFilter[]> {
    const response = await this.useFetch('GET', 'getStatus', this.url_admin_filters_status)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }
}
