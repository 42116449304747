import { Button, TemplateModal, TemplateModalAlignment } from '@mit/hui'
import React from 'react'
import { AuthProvider } from '../authorization'
import { RegionalConfig } from '../types'

interface SessionExpirationModalProps {
  regionConfig: RegionalConfig
  customBody?: string
}

export const SessionExpirationModal: React.FC<SessionExpirationModalProps> = ({ regionConfig, customBody }) => {
  return (
    <TemplateModal
      name=""
      show={true}
      noExternalClose
      bodyAlignment={TemplateModalAlignment.Center}
      header={<h2>Session has expired</h2>}
      body={<>{customBody ?? <p className="mt-3">Your session has expired. The application will reload and any progress may be lost.</p>}</>}
      footer={
        <>
          <Button
            text="Ok"
            onClick={() => {
              const authProvider = new AuthProvider(regionConfig)
              authProvider.refreshToken().catch((error) => console.error(error))
            }}
          />
        </>
      }
    />
  )
}
