import { BaseController } from './BaseController'

export interface PersonAws {
  mitid: string
  kerberos_id: string
  first_name: string
  last_name: string
  display_name: string
}

export interface PersonAutocomplete {
  email: string
  firstName: string
  krbName: string
  lastName: string
  middleName: string
  mitId: string
}

export interface FetchPeopleResponseAws {
  people?: PersonAutocomplete[]
  error?: any
}

export class PeopleController extends BaseController {
  readonly url_people: string

  constructor () {
    super()
    this.url_people = `${this.apiHost}/${this.apiPathAccount}/user/search?search=`
  }

  checkStatusCode (status: number): boolean {
    return status >= 200 && status < 300
  }

  fetchPeopleAws = async (query: string): Promise<PersonAutocomplete[]> => {
    const response = await this.useFetch('GET', 'fetchPeopleAws', this.url_people + query)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }
}
