import React, { FC } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { CoreProvider, useStoreState } from 'Store'
import reportWebVitals from './reportWebVitals'
import { useAppInitializer } from 'hooks/Initializer'
import 'Scss/main.scss'
import { Initialization, Logout } from '@mit/aws-react'
import appConfig from 'app-config'

const App = React.lazy(async () => await import('Components/app/App').then(module => ({ default: module.App })))

const WrappedApp: FC = () => {
  const { appName, appContact, appDescription } = useStoreState(state => state.AppConfigModel)
  const { initializeApp } = useAppInitializer()

  return (
    <>
      <Helmet titleTemplate={`${appName} - %s`} defaultTitle={appName}>
        {/* <title>{appName}</title> */}
        <meta name='description' content={appDescription} />
      </Helmet>
      <Router>
        <React.StrictMode>
          <Switch>
            <Route exact path='/logout' component={Logout} />
            <Route path='/'>
              <Initialization
                App={App}
                appInitializer={initializeApp}
                appConfig={appConfig}
                useCognitoAuthentication
                loaderProps={{
                  contactEmail: appContact,
                  name: appName,
                  exit: false
                  // theme: appTheme
                }}
              />
            </Route>
          </Switch>
        </React.StrictMode>
      </Router>
    </>
  )
}

ReactDOM.render(
  <CoreProvider>
    <WrappedApp />
  </CoreProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
