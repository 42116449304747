import { Action, action } from 'easy-peasy'
import { Profile } from 'Types'

interface State {
  profile: Profile
  profilePicture: string
}
interface Actions {
  updateProfile: Action<this, Profile>
  updateProfilePicture: Action<this, string>
}

export interface IProfileModel extends State, Actions {}

export const ProfileModel: IProfileModel = {
  profile: {
    department: '',
    display_name: '',
    email: '',
    first_name: '',
    initials: '',
    kerberos: '',
    kerberos_id: '',
    krb_name: '',
    last_name: '',
    mit_id: '',
    mitid: '',
    office_location: '',
    phone_number: '',
    source: ''
  },
  profilePicture: '',

  // ACTIONS
  updateProfile: action((state, payload) => {
    state.profile = { ...state.profile, ...payload }
  }),
  updateProfilePicture: action((state, payload) => {
    state.profilePicture = payload
  })
}
