
import { BaseApiController, Region, RegionalConfig, AuthProvider, AppConfig } from '@mit/aws-react'
import appConfig from '../../app-config'

// Override any methods from TemplateConstant BaseController here. Extend all other controllers off this controller
export class BaseController extends BaseApiController {
  public readonly apiPath: string
  public readonly appConfig: AppConfig
  public readonly apiHost: string
  public readonly apiPathDigitalId: string
  private readonly activeRegion: RegionalConfig
  readonly apiPathAccount: string

  constructor () {
    super()

    this.appConfig = (appConfig as unknown) as AppConfig
    const region = new Region(this.appConfig)

    this.activeRegion = region.getActiveRegionConfig()
    this.apiHost = `https://${this.activeRegion.api.domain}`
    this.apiPath = this.activeRegion.api.paths.elo
    this.apiPathDigitalId = this.activeRegion.api.paths['digital-id']
    this.apiPathAccount = this.activeRegion.api.paths.account
  }

  /*
    If you do not use cognito, implement your authorization header logic here
  */
  public async getAuthorizationHeaders (): Promise<Record<string, string>> {
    const token = await new AuthProvider(this.activeRegion).getToken()

    return {
      Authorization: `Bearer ${token}`
    }
  }
}
