import { appInitialized } from '@mit/aws-react'
import { useStoreActions } from 'Store'
import { Access, AccountController, Department, LookupController, Sponsor, StatusFilter } from 'Api/controller'
import { DepartmentListItems } from 'Store/models/Lookup'
import { Permission } from 'Store/models/Global'
import { ActivePage } from 'Store/models/Account'
import { ListItemProps } from '@mit/hui'

const accountController = new AccountController()
const lookupController = new LookupController()

interface UseAppInitializationReturn {
  initializeApp: () => void
}

export const useAppInitializer = (): UseAppInitializationReturn => {
  const { setPermissions } = useStoreActions(action => action.GlobalModel)
  const { setActivePage } = useStoreActions(action => action.AccountModel)
  const { setSponsorsList, setDepartmentsList, setStatusList } = useStoreActions(action => action.LookupModel)
  const location = window.location

  const getAccess = async (permissions: Access[]): Promise<Permission> => {
    const permissionObj: Permission = {}
    for (let i = 0; i < permissions.length; i++) {
      Object.assign(permissionObj, { [`${permissions[i].permission}`]: permissions[i].enabled })
    }
    return permissionObj
  }

  const page = (permissions: {[key: string]: boolean}): void => {
    const { viewAffiliates, viewEmployeeList } = permissions
    if (location.pathname === '/') {
      if (!viewAffiliates && viewEmployeeList) setActivePage(ActivePage.Staff)
      else if (!viewAffiliates && !viewEmployeeList) setActivePage(ActivePage.Students)
      else setActivePage(ActivePage.Affiliates)
    }
    if (location.pathname === '/staff') setActivePage(ActivePage.Staff)
    if (location.pathname === '/students') setActivePage(ActivePage.Students)
    if (location.pathname === '/visitors') setActivePage(ActivePage.Visitors)
  }

  const initializeApp = async (): Promise<void> => {
    // Simulate api call. Don't use timeout
    // You can access easy peasy state in this hook

    const permissionsPromise = accountController.getPermissions()
    const sponsorsPromise = lookupController.getSponsors()
    const departmentsPromise = lookupController.getDepartments()
    const statusPromise = lookupController.getStatus()

    Promise.allSettled([permissionsPromise, sponsorsPromise, departmentsPromise, statusPromise])
      .then(async resp => {
        if (resp[1].status === 'fulfilled') {
          if (resp[1].value.length >= 1) {
            const sponsorsList = resp[1].value.map((sponsor: Sponsor) => {
              return {
                icon: '',
                text: `${sponsor.firstName} ${sponsor.lastName}`,
                mitId: sponsor.mitId,
                kerb: sponsor.kerberos,
                dlc: sponsor.dlc
              }
            })
            setSponsorsList(sponsorsList)
          }
        }

        if (resp[2].status === 'fulfilled') {
          if (resp[2].value !== undefined) {
            const formatData = (data: Array<{[key: string]: any}>): ListItemProps[] => {
              return data.map((department: Department) => {
                return {
                  icon: '',
                  text: department.departmentName ?? '',
                  id: department.dlcKey ?? ''
                }
              })
            }
            const formatedLists: DepartmentListItems = {
              affiliates: formatData(resp[2].value.affiliates ?? []),
              staff: formatData(resp[2].value.staff ?? []),
              student: formatData(resp[2].value.student ?? [])
            }
            setDepartmentsList(formatedLists)
          }
        }

        if (resp[3].status === 'fulfilled') {
          if (resp[3].value.length >= 1) {
            const statusList = resp[3].value.map((status: StatusFilter) => {
              return {
                icon: '',
                text: status.key ?? '',
                filterKey: status.filterKey ?? ''
              }
            })
            setStatusList(statusList)
          }
        }

        if (resp[0].status === 'fulfilled') {
          const access: Access[] = resp[0].value
          setPermissions(access)
          const permissionsAccess: Permission = await getAccess(access)
          if (
            permissionsAccess.viewEmployeeList ||
            permissionsAccess.viewUndergrads ||
            permissionsAccess.viewGrads ||
            permissionsAccess.viewVisitors ||
            permissionsAccess.viewAffiliates
          ) {
            page(permissionsAccess)
            appInitialized({
              isLoading: false,
              hasAccess: true,
              hasError: false
            })
          } else {
            appInitialized({
              isLoading: false,
              hasAccess: false,
              hasError: false
            })
          }
        }
      })
      .catch(err => console.error(err))

    // setTimeout(() => {
    //   appInitialized({
    //     isLoading: false,
    //     hasAccess: true,
    //     hasError: false
    //   })
    // }, 3000)
  }

  return { initializeApp }
}
