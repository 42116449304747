import { Action, action } from 'easy-peasy'
import { Access } from 'Api/controller'

export interface Permission {
  [key: string]: boolean
}
interface State {
  auth: object
  permissions: Permission
  mobile: boolean
}
interface Actions {
  setAuth: Action<this, object>
  setPermissions: Action<this, Access[]>
  setMobile: Action<this, boolean>
}
// eslint-disable-next-line
interface Thunks {}

export interface IGlobalModel extends State, Actions, Thunks {}

export const GlobalModel: IGlobalModel = {
  auth: {},
  permissions: {},
  mobile: false,

  // Actions
  setAuth: action((state, payload) => {
    state.auth = payload
  }),

  setPermissions: action((state, payload) => {
    const permissionObj: Permission = {}
    for (let i = 0; i < payload.length; i++) {
      Object.assign(permissionObj, { [`${payload[i].permission}`]: payload[i].enabled })
    }
    // const testPermissionObj = {
    //   ...permissionObj,
    //   viewVisitors: false,
    //   viewGrads: true,
    //   viewUndergrads: true,
    //   viewAffiliates: false,
    //   viewEmployeeList: true
    // }
    // state.permissions = testPermissionObj
    // console.log('key::permissions', testPermissionObj)
    state.permissions = permissionObj
  }),

  setMobile: action((state, payload) => {
    state.mobile = payload
  })

}
